var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('resource-list',{directives:[{name:"page-title",rawName:"v-page-title",value:('POs'),expression:"'POs'"}],attrs:{"resource-class":_vm.$resources.PurchaseOrderResource,"resolve-attributes":['batch.bank.currency.code'],"fields":_vm.listFields,"filters":_vm.listFilters,"title":"Purchase Orders"},scopedSlots:_vm._u([{key:"filters-after",fn:function(){return [_c('b-button',{directives:[{name:"user-scope-or",rawName:"v-user-scope-or.disabled",value:(['dataentry']),expression:"['dataentry']",modifiers:{"disabled":true}}],staticClass:"ml-auto",attrs:{"to":{ name: 'purchaseOrderCreate', props: { instanceName: _vm.instanceName } }}},[_vm._v("Add Purchase Order ")])]},proxy:true},{key:"value-number",fn:function(ref){
var resource = ref.resource;
return [_c('resource-format',{attrs:{"display-template":"number","resource":resource,"linked":""},on:{"click":function($event){return _vm.$router.push({
                    name: 'purchaseOrderDetail',
                    params: { instanceName: _vm.instanceName, id: resource.id },
                })}}})]}},{key:"value-status",fn:function(ref){
                var resource = ref.resource;
return [_vm._v(" "+_vm._s(resource.prettyStatus)+" ")]}},{key:"value-amount_decimal",fn:function(ref){
                var resource = ref.resource;
return [_c('resource-format',{attrs:{"display-template":"amount_decimal","resource":resource,"currency":resource.get('batch.bank.currency.code'),"append-text":' ' + resource.get('batch.bank.currency.code')}})]}},{key:"value-remaining",fn:function(ref){
                var resource = ref.resource;
return [_c('resource-format',{attrs:{"display-template":"remaining","resource":resource,"currency":resource.get('batch.bank.currency.code'),"append-text":' ' + resource.get('batch.bank.currency.code')}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }