<script>
import Vue from 'vue'

import BaseList from '../../components/BaseList'
import PurchaseOrderResourceList from '../../components/transactions/PurchaseOrderResourceList'
import AccountsPayableResourceList from '../../components/transactions/AccountsPayableResourceList'
import PayrollResourceList from '../../components/transactions/PayrollResourceList'
import CheckResourceList from '../../components/transactions/CheckResourceList'
import EmployeeResourceList from '../../components/system/EmployeeResourceList'
import ReportRenderResourceList from '../../components/reports/index'

export default Vue.extend({
    name: 'ResourceList',
    components: {
        BaseList,
        CheckResourceList,
        PurchaseOrderResourceList,
        PayrollResourceList,
        AccountsPayableResourceList,
        EmployeeResourceList,
        ReportRenderResourceList,
    },

    props: {
        instanceName: {
            type: String,
            required: true,
        },
        resourceClassName: {
            type: String,
            required: true,
        },
        linkPrimaryKey: {
            type: String,
            default: 'id',
        },
        linkColumns: {
            type: Array,
            default() {
                return []
            },
        },
    },

    computed: {
        attributes: ({ id, instanceName, resourceClassName, linkPrimaryKey, linkColumns }) => ({
            id,
            instanceName,
            resourceClassName,
            linkPrimaryKey,
            linkColumns,
        }),
        resourceListPage: ({ $options, resourceClassName }) => $options.components[`${resourceClassName}List`] || BaseList,
    },
})
</script>

<template>
    <component :is="resourceListPage" ref="listComponent" v-bind="attributes" />
</template>
