<script>
import Vue from 'vue'
import { ResourceListField, ResourceListAction, ListFilterSelect } from '@sigmacloud/sigma-client/dist/components/core/resource_list'

export default Vue.extend({
    props: {
        instanceName: {
            type: String,
            required: true,
        },
    },
    computed: {
        listFields() {
            return [
                new ResourceListField('ID', 'ledger'),
                new ResourceListField('Number', 'number'),
                new ResourceListField('Vendor', 'vendor.alias_name'),
                new ResourceListField('Bank', 'batch.bank.name'),
                new ResourceListField('Amount', 'amount_decimal'),
            ]
        },
        listFilters() {
            return [
                new ListFilterSelect('Status', 'status', {
                    value: ['CLOSED', 'POSTED'],
                    choices: ['POSTED', 'OPEN', 'CLOSED', 'DRAFT'],
                }),
            ]
        },
        listActions() {
            return [
                new ResourceListAction('Reload Selected', {
                    async handler(resources) {
                        return await Promise.all(resources.map((resource) => resource.update()))
                    },
                }),
            ]
        },
    },
})
</script>

<template>
    <div class="home">
        <resource-list
            ref="list"
            v-page-title="'APs'"
            :resource-class="$resources.AccountsPayableResource"
            :resolve-attributes="['batch.bank.currency.code']"
            :fields="listFields"
            :filters="listFilters"
            :actions="listActions">
            <template #filters-after>
                <b-button v-user-scope-or.disabled="['dataentry']" class="ml-auto" :to="{ name: 'accountsPayableCreate', props: { instanceName } }"
                    >Add Accounts Payable
                </b-button>
            </template>
            <template #value-amount_decimal="{ resource }">
                <resource-format
                    display-template="amount_decimal"
                    :resource="resource"
                    :currency="resource.get('batch.bank.currency.code')"
                    :append-text="' ' + resource.get('batch.bank.currency.code')">
                </resource-format>
            </template>
            <template #value-number="{ resource }">
                <resource-format
                    display-template="number"
                    :resource="resource"
                    linked
                    @click="
                        $router.push({
                            name: 'accountsPayableDetail',
                            params: { instanceName, id: resource.id },
                        })
                    ">
                </resource-format>
            </template>
            <template #header-amount_decimal>
                <div class="text-right">Amount</div>
            </template>
        </resource-list>
    </div>
</template>
