<script>
import Vue from 'vue'
import BaseList from '../BaseList'
import { ListFilterSelect, ListFilterRange, ListFilterDateRange, ResourceListAction } from '@sigmacloud/sigma-client/dist/components/core/resource_list'
import {
    BasicBenefitsReport,
    CustomTimeEntryReport,
    DetailPayRecapReport,
    CustomFinalInvoiceReport,
    CheckRegisterReport,
    DetailCheckRegisterReport,
    FringeRecapReport,
    AccountDetailReport,
    DetailEditReport,
    WCInvoiceReport,
    EmployeeCheckStubHistoryReport,
} from '@sigmacloud/sigma-client/dist/reports/payroll/index'

export default Vue.extend({
    extends: BaseList,
    data() {
        return {
            reportActions: [
                new ResourceListAction('Report: Custom Time Entry - Audit', {
                    handler: async (payrolls) =>
                        await Promise.all(
                            payrolls.map((payroll) => {
                                let report = new CustomTimeEntryReport({ payroll: payroll.id }, { report_template: 'audit' })
                                return report.generateAndDownloadPDF(`${payroll.id} - ${CustomTimeEntryReport.NAME}-${Date.now()}.pdf`)
                            })
                        ),
                }),
                new ResourceListAction('Report: Custom Time Entry - Summary', {
                    handler: async (payrolls) =>
                        await Promise.all(
                            payrolls.map((payroll) => {
                                let report = new CustomTimeEntryReport({ payroll: payroll.id }, { report_template: 'summary' })
                                return report.generateAndDownloadPDF(`${payroll.id} - ${CustomTimeEntryReport.NAME}-${Date.now()}.pdf`)
                            })
                        ),
                }),
                new ResourceListAction('Report: Detail Pay Recap - Billed', {
                    handler: async (payrolls) =>
                        await Promise.all(
                            payrolls.map((payroll) => {
                                let report = new DetailPayRecapReport({ payroll: payroll.id }, { report_template: 'detail_pay_recap_billed' })
                                return report.generateAndDownloadPDF(`${payroll.id} - ${DetailPayRecapReport.NAME}-${Date.now()}.pdf`)
                            })
                        ),
                }),
                new ResourceListAction('Report: Detail Pay Recap - Actuals', {
                    handler: async (payrolls) =>
                        await Promise.all(
                            payrolls.map((payroll) => {
                                let report = new DetailPayRecapReport({ payroll: payroll.id }, { report_template: 'detail_pay_recap_actuals' })
                                return report.generateAndDownloadPDF(`${payroll.id} - ${DetailPayRecapReport.NAME}-${Date.now()}.pdf`)
                            })
                        ),
                }),
                new ResourceListAction('Report: Custom Final Invoice', {
                    handler: async (payrolls) =>
                        await Promise.all(
                            payrolls.map((payroll) => {
                                let report = new CustomFinalInvoiceReport(
                                    { payroll: payroll.id, batch: payroll.attributes.batch },
                                    { report_template: 'default' }
                                )
                                return report.generateAndDownloadPDF(`${payroll.id} - ${CustomFinalInvoiceReport.NAME}-${Date.now()}.pdf`)
                            })
                        ),
                }),
                new ResourceListAction('Report: Basic Benefits', {
                    handler: async (payrolls) =>
                        await Promise.all(
                            payrolls.map((payroll) => {
                                let report = new BasicBenefitsReport({ payroll: payroll.id, mask_ssn: true })
                                return report.generateAndDownloadPDF(`${payroll.id} - ${BasicBenefitsReport.NAME}-${Date.now()}.pdf`)
                            })
                        ),
                }),
                new ResourceListAction('Report: Payroll Wage Check Register', {
                    handler: async (payrolls) =>
                        await Promise.all(
                            payrolls.map((payroll) => {
                                let report = new CheckRegisterReport({ payroll: payroll.id }, { report_template: 'default' })
                                return report.generateAndDownloadPDF(`${payroll.id} - ${DetailPayRecapReport.NAME}-${Date.now()}.pdf`)
                            })
                        ),
                }),
                new ResourceListAction('Report: Payroll Detail Check Register - Default/Detail (Timing Out On Old System)', {
                    handler: async (payrolls) =>
                        await Promise.all(
                            payrolls.map((payroll) => {
                                let report = new DetailCheckRegisterReport({ payroll: payroll.id }, { report_template: 'default' })
                                return report.generateAndDownloadPDF(`${payroll.id} - ${DetailPayRecapReport.NAME}-${Date.now()}.pdf`)
                            })
                        ),
                }),
                new ResourceListAction('Report: Payroll Detail Check Register - Summary (Timing Out On Old System)', {
                    handler: async (payrolls) =>
                        await Promise.all(
                            payrolls.map((payroll) => {
                                let report = new DetailCheckRegisterReport({ payroll: payroll.id }, { report_template: 'summary' })
                                return report.generateAndDownloadPDF(`${payroll.id} - ${DetailPayRecapReport.NAME}-${Date.now()}.pdf`)
                            })
                        ),
                }),
                new ResourceListAction('Report: Fringe Recap', {
                    handler: async (payrolls) =>
                        await Promise.all(
                            payrolls.map((payroll) => {
                                let report = new FringeRecapReport({ payroll: payroll.id }, { report_template: 'detail' })
                                return report.generateAndDownloadPDF(`${payroll.id} - ${DetailPayRecapReport.NAME}-${Date.now()}.pdf`)
                            })
                        ),
                }),
                new ResourceListAction('Report: Account Detail', {
                    handler: async (payrolls) =>
                        await Promise.all(
                            payrolls.map((payroll) => {
                                let report = new AccountDetailReport({ payroll: payroll.id }, { report_template: 'default' })
                                return report.generateAndDownloadPDF(`${payroll.id} - ${DetailPayRecapReport.NAME}-${Date.now()}.pdf`)
                            })
                        ),
                }),
                new ResourceListAction('Report: Account Detail By Job', {
                    handler: async (payrolls) =>
                        await Promise.all(
                            payrolls.map((payroll) => {
                                let report = new AccountDetailReport({ payroll: payroll.id }, { report_template: 'job' })
                                return report.generateAndDownloadPDF(`${payroll.id} - ${DetailPayRecapReport.NAME}-${Date.now()}.pdf`)
                            })
                        ),
                }),
                new ResourceListAction('Report: AICP Account Detail By Job', {
                    handler: async (payrolls) =>
                        await Promise.all(
                            payrolls.map((payroll) => {
                                let report = new AccountDetailReport({ payroll: payroll.id }, { report_template: 'aicp' })
                                return report.generateAndDownloadPDF(`${payroll.id} - ${DetailPayRecapReport.NAME}-${Date.now()}.pdf`)
                            })
                        ),
                }),
                new ResourceListAction('Report: Invoice Bundle (PR Taxes and WC)', {
                    handler: async (payrolls) =>
                        await Promise.all(
                            payrolls.map((payroll) => {
                                let report = new CustomFinalInvoiceReport(
                                    { payroll: payroll.id, batch: payroll.attributes.batch },
                                    { report_template: 'default' }
                                )
                                return report.generateAndDownloadPDF(`${payroll.id} - ${DetailPayRecapReport.NAME}-${Date.now()}.pdf`)
                            })
                        ),
                }),
                new ResourceListAction('Report: Detail Payroll Edit', {
                    handler: async (payrolls) =>
                        await Promise.all(
                            payrolls.map((payroll) => {
                                let report = new DetailEditReport({ payroll: payroll.id }, { report_template: 'default' })
                                return report.generateAndDownloadPDF(`${payroll.id} - ${DetailPayRecapReport.NAME}-${Date.now()}.pdf`)
                            })
                        ),
                }),
                new ResourceListAction('Report: Basic Benefits Report', {
                    handler: async (payrolls) =>
                        await Promise.all(
                            payrolls.map((payroll) => {
                                let report = new BasicBenefitsReport({ payroll: payroll.id }, { report_template: 'default' })
                                return report.generateAndDownloadPDF(`${payroll.id} - ${DetailPayRecapReport.NAME}-${Date.now()}.pdf`)
                            })
                        ),
                }),
                new ResourceListAction('Report: WC Bundle Invoice (Broken On Old System)', {
                    handler: async (payrolls) =>
                        await Promise.all(
                            payrolls.map((payroll) => {
                                let report = new WCInvoiceReport({ payroll: payroll.id }, { report_template: 'default' })
                                return report.generateAndDownloadPDF(`${payroll.id} - ${DetailPayRecapReport.NAME}-${Date.now()}.pdf`)
                            })
                        ),
                }),
            ],
            statusActions: [
                new ResourceListAction('Set Status To: DRAFT', {
                    async handler(payrolls) {
                        payrolls.forEach((payroll) => payroll.set('status', 'DRAFT'))
                        return await Promise.all(payrolls.map((payroll) => payroll.save()))
                    },
                }),
                new ResourceListAction('Set Status To: OPEN', {
                    async handler(payrolls) {
                        payrolls.forEach((payroll) => payroll.set('status', 'OPEN'))
                        return await Promise.all(payrolls.map((payroll) => payroll.save()))
                    },
                }),
                new ResourceListAction('Set Status To: AUDITED', {
                    async handler(payrolls) {
                        payrolls.forEach((payroll) => payroll.set('status', 'AUDITED'))
                        return await Promise.all(payrolls.map((payroll) => payroll.save()))
                    },
                }),
                new ResourceListAction('Set Status To: POSTED', {
                    async handler(payrolls, { userResource }) {
                        payrolls.forEach((payroll) => {
                            if (payroll.attributes.status !== 'AUDITED') return
                            payroll.set('status', 'POSTED')
                        })
                        return await Promise.all(
                            payrolls.map(async (payroll) => {
                                if (payroll.attributes.status !== 'POSTED') return
                                let response = await payroll.postPayroll()
                                return payroll.statusWait(response.data.post_status_id)
                            })
                        )
                    },
                    params: {
                        userResource: this.$store.getters.userResource,
                    },
                }),
                new ResourceListAction('Set Status To: CLOSED', {
                    async handler(payrolls, { userResource }) {
                        payrolls.forEach((payroll) => payroll.set('status', 'CLOSED'))
                        return await Promise.all(payrolls.map((payroll) => payroll.save()))
                    },
                    params: {
                        userResource: this.$store.getters.userResource,
                    },
                }),
                new ResourceListAction('Set Status To: VOIDED', {
                    async handler(payrolls, { userResource }) {
                        payrolls.forEach((payroll) => payroll.set('status', 'VOIDED'))
                        return await Promise.all(payrolls.map((payroll) => payroll.save()))
                    },
                    params: {
                        userResource: this.$store.getters.userResource,
                    },
                }),
                new ResourceListAction('Set Status To: DELETED', {
                    async handler(payrolls, { userResource }) {
                        payrolls.forEach((payroll) => payroll.set('status', 'DELETED'))
                        return await Promise.all(payrolls.map((payroll) => payroll.save()))
                    },
                    params: {
                        userResource: this.$store.getters.userResource,
                    },
                }),
            ],
            listFilters: [
                new ListFilterSelect('Status', 'status', {
                    value: ['DRAFT', 'OPEN', 'AUDITED', 'POSTED', 'CLOSED', 'VOIDED', 'DELETED'],
                    choices: [
                        { value: 'DRAFT', text: 'Draft' },
                        { value: 'OPEN', text: 'Open' },
                        { value: 'AUDITED', text: 'Audited' },
                        { value: 'POSTED', text: 'Posted' },
                        { value: 'CLOSED', text: 'Closed' },
                        { value: 'VOIDED', text: 'Voided' },
                        { value: 'DELETED', text: 'Deleted' },
                    ],
                    many: true,
                }),
                new ListFilterRange('ID', 'id'),
                new ListFilterDateRange('Pay Period Start Date', 'pay_period_start_date'),
                new ListFilterDateRange('Pay Period End Date', 'pay_period_end_date'),
                new ListFilterDateRange('Due Date', 'date_due'),
                new ListFilterDateRange('Check Date', 'check_date'),
            ],
        }
    },

    computed: {
        listActions: ({ reportActions, statusActions }) => [...reportActions, ...statusActions],
    },
})
</script>

<template>
    <resource-list
        v-page-title="pageTitle"
        :resource-class="$resources[resourceClassName]"
        :fields="listFields"
        :title="pageTitle"
        :filters="listFilters"
        :actions="listActions">
        <template #filters-after>
            <b-button class="ml-auto" :to="{ name: `payrollResourcePrintableList`, props: { instanceName } }"> Printable Payrolls </b-button>
            <b-button class="ml-2" :to="{ name: `${camelCaseResource}Create`, props: { instanceName } }"> Add {{ readableResourceClass }} </b-button>
        </template>
        <template v-for="column in linkColumns" #[`value-${column}`]="{ resource }">
            <resource-format
                :key="column"
                :display-template="column"
                :resource="resource"
                linked
                @click="
                    $router.push({
                        name: `${camelCaseResource}Detail`,
                        params: { instanceName, id: resource.attributes[linkPrimaryKey] },
                    })
                " />
        </template>
    </resource-list>
</template>
