<script>
import Vue from 'vue'
import BaseList from '../BaseList'

export default Vue.extend({
    name: 'EmployeeResourceList',
    extends: BaseList,
    methods: {
        goToResource(resource) {
            return this.$router.push({
                name: `${this.camelCaseResource}Detail`,
                params: { instanceName: this.instanceName, id: resource.attributes[this.linkPrimaryKey] },
            })
        },
        getListFn(queryString) {
            let search = {
                fn: 'list',
                pageDataSetter: null,
            }
            if (queryString) {
                let cleanQuery = queryString.replaceAll('-', '')

                let setPageData = async (listFn) => {
                    let tin = await listFn({ query: { tin_last_4: cleanQuery } })
                    if (tin.count !== 0) {
                        return tin
                    } else {
                        return await listFn({ query: { fein_last_4: cleanQuery } })
                    }
                }

                let isFourDigits = cleanQuery && cleanQuery.match(/^\d{4}$/)
                if (isFourDigits) {
                    search.pageDataSetter = setPageData
                } else {
                    let tinMatch = cleanQuery && cleanQuery.match(/^\d{9}$/)
                    if (tinMatch) {
                        search.fn = 'tin'
                    }
                }
            }
            return search
        },
    },
})
</script>

<template>
    <resource-list
        v-page-title="pageTitle"
        :resource-class="$resources[resourceClassName]"
        :fields="listFields"
        :title="pageTitle"
        :filters="listFilters"
        :custom-search="getListFn">
        <template #filters-after>
            <b-button class="ml-auto" :to="{ name: `${camelCaseResource}Create`, props: { instanceName } }"> Add {{ readableResourceClass }} </b-button>
        </template>
        <template #[`value-tin_last_4`]="{ resource }">
            <resource-format :display-template="'tin_last_4'" :resource="resource" linked @click="goToResource(resource)" />
        </template>
        <template #value-last_name="{ resource }">
            <resource-format
                :display-template="`${
                    resource.attributes.is_loanout ? '${last_name}, ${first_name} (${loanout_name} - ${fein_last_4})' : '${last_name}, ${first_name}'
                }`"
                :resource="resource"
                linked
                @click="goToResource(resource)" />
        </template>
    </resource-list>
</template>
