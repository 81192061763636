var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('resource-list',{directives:[{name:"page-title",rawName:"v-page-title",value:('APs'),expression:"'APs'"}],ref:"list",attrs:{"resource-class":_vm.$resources.AccountsPayableResource,"resolve-attributes":['batch.bank.currency.code'],"fields":_vm.listFields,"filters":_vm.listFilters,"actions":_vm.listActions},scopedSlots:_vm._u([{key:"filters-after",fn:function(){return [_c('b-button',{directives:[{name:"user-scope-or",rawName:"v-user-scope-or.disabled",value:(['dataentry']),expression:"['dataentry']",modifiers:{"disabled":true}}],staticClass:"ml-auto",attrs:{"to":{ name: 'accountsPayableCreate', props: { instanceName: _vm.instanceName } }}},[_vm._v("Add Accounts Payable ")])]},proxy:true},{key:"value-amount_decimal",fn:function(ref){
var resource = ref.resource;
return [_c('resource-format',{attrs:{"display-template":"amount_decimal","resource":resource,"currency":resource.get('batch.bank.currency.code'),"append-text":' ' + resource.get('batch.bank.currency.code')}})]}},{key:"value-number",fn:function(ref){
var resource = ref.resource;
return [_c('resource-format',{attrs:{"display-template":"number","resource":resource,"linked":""},on:{"click":function($event){return _vm.$router.push({
                        name: 'accountsPayableDetail',
                        params: { instanceName: _vm.instanceName, id: resource.id },
                    })}}})]}},{key:"header-amount_decimal",fn:function(){return [_c('div',{staticClass:"text-right"},[_vm._v("Amount")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }