<script>
import Vue from 'vue'
import BaseList from '../BaseList'
import {
    ListFilterRange,
    ListFilterDateRange,
    ListFilterRelatedResource,
    ResourceListAction,
} from '@sigmacloud/sigma-client/dist/components/core/resource_list'

export default Vue.extend({
    name: 'CheckResourceList',
    extends: BaseList,
    data() {
        return {
            listActions: [
                new ResourceListAction('Void and Reissue (Not Functional)', {
                    handler: async (checks) =>
                        await Promise.all(
                            checks.map((check) => {
                                return true || check
                            })
                        ),
                }),
                new ResourceListAction('Void and Credit Client (Not Functional', {
                    handler: async (checks) =>
                        await Promise.all(
                            checks.map((check) => {
                                return true || check
                            })
                        ),
                }),
                new ResourceListAction('Check Reverse and Manual Reissue (Not Functional', {
                    handler: async (checks) =>
                        await Promise.all(
                            checks.map((check) => {
                                return true || check
                            })
                        ),
                }),
            ],
            listFilters: [
                new ListFilterRange('Number', 'number'),
                new ListFilterDateRange('Check Date', 'check_date'),
                new ListFilterRelatedResource('Employee', 'employee', { resource: new this.$resources[this.resourceClassName]() }),
                new ListFilterRelatedResource('Client', 'client', { resource: new this.$resources[this.resourceClassName]() }),
                new ListFilterRelatedResource('Project', 'project', { resource: new this.$resources[this.resourceClassName]() }),
                new ListFilterRelatedResource('Payroll', 'payroll', { resource: new this.$resources[this.resourceClassName]() }),
            ],
        }
    },
    methods: {
        async getAccountsPayableTransactionIds(apQueryParams) {
            const { employeeId, clientId, projectId, payrollId } = apQueryParams
            let apData = await this.$resources.AccountsPayableResource.list({
                query: { employee: employeeId, payroll__project__client: clientId, payroll__project: projectId, payroll: payrollId },
            })
            let transactionsIds = apData.resources.map((apdr) => apdr.id)
            return transactionsIds.join(',')
        },
        getCheckQuery(checkQueryParams) {
            const { transactionIds, checkNumber, checkDate, searchString } = checkQueryParams
            let queryObj = { query: { related_transactions__in: transactionIds } }

            if (checkNumber && checkNumber.from) queryObj.query.number__gte = checkNumber.from
            if (checkNumber && checkNumber.to) queryObj.query.number__lte = checkNumber.to
            if (checkDate && checkDate.from) queryObj.query.check_date__gte = checkDate.from
            if (checkDate && checkDate.to) queryObj.query.check_date__lte = checkDate.to
            if (searchString) queryObj.query.search = searchString

            return queryObj
        },
        getListFn(searchString) {
            let search = {
                fn: 'list',
                pageDataSetter: null,
            }
            let employeeId,
                clientId,
                projectId,
                payrollId,
                checkNumber,
                checkDate = null
            for (let filter of this.listFilters) {
                if (filter.field === 'employee') employeeId = filter.value
                if (filter.field === 'client') clientId = filter.value
                if (filter.field === 'project') projectId = filter.value
                if (filter.field === 'payroll') payrollId = filter.value

                if (filter.field === 'number') checkNumber = filter.value
                if (filter.field === 'check_date') checkDate = filter.value
            }
            if (employeeId || clientId || projectId || payrollId) {
                let setPageData = async (listFn) => {
                    let transactionIds = await this.getAccountsPayableTransactionIds({ employeeId, clientId, projectId, payrollId })
                    let queryObj = this.getCheckQuery({ transactionIds, checkNumber, checkDate, searchString })
                    return await listFn(queryObj)
                }
                search.pageDataSetter = setPageData
            }
            return search
        },
    },
})
</script>

<template>
    <resource-list
        v-page-title="pageTitle"
        :resource-class="$resources[resourceClassName]"
        :fields="listFields"
        :title="pageTitle"
        :filters="listFilters"
        :actions="listActions"
        @preSearch="preSearch"
        :custom-search="getListFn">
        <template #filters-after>
            <b-button disabled class="ml-auto" :to="{ name: `${camelCaseResource}Create`, props: { instanceName } }">
                Add {{ readableResourceClass }}
            </b-button>
        </template>
        <template v-for="column in linkColumns" #[`value-${column}`]="{ resource }">
            <resource-format
                :key="column"
                :display-template="column"
                :resource="resource"
                linked
                @click="
                    $router.push({
                        name: `${camelCaseResource}Detail`,
                        params: { instanceName, id: resource.attributes[linkPrimaryKey] },
                    })
                " />
        </template>
    </resource-list>
</template>
