<script>
import Vue from 'vue'
import {
    ResourceListAction,
    ListFilterSelect,
    ListFilterCheckbox,
    ListFilterRelatedResource,
} from '@sigmacloud/sigma-client/dist/components/core/resource_list'
import BaseListVue from '../BaseList.vue'

export default Vue.extend({
    name: 'ReportList',
    extends: BaseListVue,
    computed: {
        readableResourceClass: () => 'Report',
        listFilters() {
            let username = this.$store.getters.userResource.attributes.username
            return [
                new ListFilterSelect('Status', 'status', {
                    value: ['QUEUED', 'WAITING', 'DONE', 'ERROR'],
                    choices: [
                        { value: 'QUEUED', text: 'Queued' },
                        { value: 'WAITING', text: 'Waiting' },
                        { value: 'DONE', text: 'Done' },
                        { value: 'ERROR', text: 'Error' },
                    ],
                    many: true,
                }),
                new ListFilterCheckbox('Show only my reports', 'generated_by', {
                    checkedValue: username,
                    uncheckedValue: '',
                }),
                new ListFilterRelatedResource('User', 'user', {
                    resource: new this.$resources['UserResource'](),
                    selectField: 'username',
                    swapFields: { from: 'user', to: 'generated_by' },
                }),
            ]
        },
        listActions() {
            return [
                new ResourceListAction('Reload Selected', {
                    async handler(resources) {
                        return await Promise.all(resources.map((resource) => resource.update()))
                    },
                }),
            ]
        },
    },
})
</script>
