<script>
import Vue from 'vue'
import { ResourceListField, ListFilterSelect } from '@sigmacloud/sigma-client/dist/components/core/resource_list'

export default Vue.extend({
    props: {
        instanceName: {
            type: String,
            required: true,
        },
    },
    computed: {
        listFields() {
            return [
                new ResourceListField('Transaction #', 'id'),
                new ResourceListField('Reference #', 'number'),
                new ResourceListField('Status', 'status'),
                new ResourceListField('Vendor', 'vendor.payee_name'),
                new ResourceListField('Description', 'description'),
                new ResourceListField('Amount', 'amount_decimal'),
                new ResourceListField('Remaining', 'remaining'),
            ]
        },
        listFilters() {
            return [
                new ListFilterSelect('Status', 'status', {
                    value: ['DRAFT', 'OPEN,AUDITED', 'POSTED', 'CLOSED', 'REJECTED'],
                    choices: [
                        { value: 'DRAFT', text: 'Draft' },
                        { value: 'OPEN,AUDITED', text: 'Not Approved' },
                        { value: 'POSTED', text: 'Approved' },
                        { value: 'CLOSED', text: 'Closed' },
                        { value: 'REJECTED', text: 'Rejected' },
                    ],
                    many: true,
                }),
            ]
        },
    },
})
</script>

<template>
    <resource-list
        v-page-title="'POs'"
        :resource-class="$resources.PurchaseOrderResource"
        :resolve-attributes="['batch.bank.currency.code']"
        :fields="listFields"
        :filters="listFilters"
        title="Purchase Orders">
        <template #filters-after>
            <b-button v-user-scope-or.disabled="['dataentry']" class="ml-auto" :to="{ name: 'purchaseOrderCreate', props: { instanceName } }"
                >Add Purchase Order
            </b-button>
        </template>
        <template #value-number="{ resource }">
            <resource-format
                display-template="number"
                :resource="resource"
                linked
                @click="
                    $router.push({
                        name: 'purchaseOrderDetail',
                        params: { instanceName, id: resource.id },
                    })
                " />
        </template>
        <template #value-status="{ resource }">
            {{ resource.prettyStatus }}
        </template>
        <template #value-amount_decimal="{ resource }">
            <resource-format
                display-template="amount_decimal"
                :resource="resource"
                :currency="resource.get('batch.bank.currency.code')"
                :append-text="' ' + resource.get('batch.bank.currency.code')" />
        </template>
        <template #value-remaining="{ resource }">
            <resource-format
                display-template="remaining"
                :resource="resource"
                :currency="resource.get('batch.bank.currency.code')"
                :append-text="' ' + resource.get('batch.bank.currency.code')" />
        </template>
    </resource-list>
</template>
